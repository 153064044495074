@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.class-details-modal {
    color: $happy-cubs-body-text;
    font-weight: 600;

    .row {
        margin: 0; // Important as this keeps everything within the modal bounds.
    }

    &-header {
        background: $primary;

        &-location,
        &-class {
            color: $white;
            font-family: 'museosansrounded';
            font-size: 2rem;
            font-weight: 800;
        }

        &-class {
            font-size: 1rem;
            font-weight: 600;
        }
    }

    &-sub-header {
        &-icon, 
        &-address {
            font-size: 1rem;
            font-family: 'museosansrounded';
            color: $primary;
            font-weight: 600;
        }
    }

    &-details {
        padding-top: 0;

        &-card {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;

            &-image {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                max-height: 180px;
                object-fit: cover;
            }

            &-overlay {
                position: absolute;
                top: 130px; // Enough to show at bottom of header
                color: $white;
                font-size: 2rem;
                font-family: 'museosansrounded';
                font-weight: 800;
            }

            &-body {
                &-dates {
                    &-icon {
                        color: $primary;
                        margin-right: 8px;
                    }
                }

                &-time {
                    &-icon {
                        color: $primary;
                        margin-right: 8px;
                    }

                    &-duration {
                        color: $primary;
                    }
                }

                &-stage {
                    &-icon {
                        width: 16px;
                        height: 16px;
                        margin-right: 8px;
                    }

                    &-ages {
                        color: $primary;
                    }
                }

                &-price {
                    &-icon {
                        color: $primary;
                        width: 16px;
                        height: 16px;
                        margin-right: 8px;
                    }

                    &-stages-info {
                        position: relative; // Required to set the z-index. Warning: The position: static property prevents the z-index from having an effect.
                        cursor: pointer;
                        z-index: 10000; // Requied as we need to be able to click the link, but the overlay is on top of it
                    }
                }
            }
        }

        &-footer {
            border-top: none;

            &-type {
                color: $primary;
                font-size: 1.5rem;
                font-family: 'museosansrounded';
                font-weight: 600;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .class-details-modal {
        &-header {
            &-class {
                text-align: right;
            }
        }
    }
}
