@import "../../../../styles/_colors.scss";

.staff-bio-component {
    border: solid 2px $primary;
    border-radius: 1rem;
    padding: 0.5rem 1rem;

    &-header {
        color: $primary;
        font-weight: 900;
        text-align: center;
    }

    &-image {
        border-radius: 50%;
        width: 200px;
        max-height: 200px;
        margin: 0 auto;
    }

    &-details {
        margin-top: 1rem;
        text-align: center;
        color: $primary;

        &-name,
        &-classType {
            font-weight: 900;
        }
    }

    &-bio {
        color: $happy-cubs-dark-gray;
    }
}
