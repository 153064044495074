@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.members-welcome-screen {
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    gap: .5rem;

    &-header {
        background: $gray-100;
        border-radius: 15px;
        padding: 10px;

        &-title {
            color: $primary;
            font-weight: 900;
        }

        &-subtitle {
            color: $happy-cubs-body-text;
            font-size: 12px;
            font-weight: 700;
            font-family: 'museosansrounded';
        }
    }

    & p {
        margin: 0;
    }
}

@include media-breakpoint-up(lg) {
    .members-welcome-screen {
        max-width: 600px;
        gap: 2rem;

        &-header {
            &-title {
                font-size: 2.5rem;
            }

            &-subtitle {
                font-size: 18px;
            }
        }
    }
}