@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.class-finder-core-location {
    margin-top: 1rem !important;
    margin-bottom: 0.5rem !important;

    &-name {
        color: $primary;
        font-size: 2.25rem;
        font-weight: 800;
        font-family: 'museosansrounded';
    }

    &-info {
        color: $primary;
        text-decoration: underline;
        font-weight: 600;

        &:hover {
            cursor: pointer;
        }
    }
}

@include media-breakpoint-up(md) {
    .class-finder-core-location {
       max-width: 640px;
       margin: 0 auto !important;
    }
}
