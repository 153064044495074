@import "../../../styles/_colors.scss";

.edit-member-child {
    text-align: center;

    &-profile-image {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        border: solid 4px $primary;
        padding: 0;
    }

    &-name {
        color: $primary;
        font-size: 3rem;
        font-weight: 900;
        text-align: center;
    }

    &-details {
        margin-bottom: 2rem;

        &-dob {
            &-label,
            &-value {
                margin: 0;
            }

            &-label {
                font-weight: 800;
            }
        }

        &-gender {
            &-label,
            &-value {
                margin: 0;
            }

            &-label {
                font-weight: 800;
            }
        }
    }

    &-health-notes {
        margin-bottom: 1rem;

        &-label {
            margin: 0;
            font-weight: 800;
        }
    }

    &-emergency-contacts-title {
        color: $primary;
        margin-top: 2rem;
        margin-bottom: 0;
    }

    &-emergency-contact {
        padding: 1rem 0;
        
        .row {
            margin-bottom: 1rem;
        }

        &-label {
            margin: 0;
            font-weight: 800;
        }

        &-value {
            margin: 0;
        }
    }
}
