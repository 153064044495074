@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.members-children-list {
    display: grid;
    gap: 1rem;

    &-notice-board {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &-item {
            border-left: solid 5px $primary;
            padding-left: 1rem;
            transition: border-color ease-in 300ms;

            &-content {
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                &-title {
                    font-weight: bold;
                }

                &-date {
                    color: $secondary;
                }
            }

            &:hover {
                border-color: rgba($primary, 0.5);
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .members-children-list {
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
    }
}

@include media-breakpoint-up(lg) {
    .members-children-list {
        grid-template-columns: repeat(3, 1fr);
    }
}

// View when a staff member has logged in but has not got a member account.
.members-children-list-staff-is-not-member {
    color: $gray-600;
    width: 100%;
    text-align: center;

    .members-children-list-staff-is-not-member-icon {
        font-size: 3rem;
    }

    .members-children-list-staff-is-not-member-actions {
        margin-top: 8px;
    }
}
