@import "../../../styles/_colors.scss";

// Column labels.
$column-label-background-color: $primary;
$column-label-color: $white;

// Row labels
$row-label-background-color: $happy-cubs-dark-gray;
$row-label-color: $white;
$row-label-max-width: 100px;

// Row labels
$row-group-background-color: darken($happy-cubs-dark-gray, 15%);
$row-group-color: $white;

$border-color: $gray-600;

.schedule-calendar-view {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $border-color;

    .schedule-calendar-view-headings {
        width: 100%;
        display: flex;
        background-color: $column-label-background-color;
        color: $column-label-color;

        .schedule-calendar-view-headings-row-and-column-label {
            width: 100%;
            max-width: $row-label-max-width;
        }

        .schedule-calendar-view-column-label {
            width: 100%;
            font-weight: bold;
            border-left: 1px solid $border-color;
            padding-left: 3px;
        }
    }

    .schedule-calendar-view-body {
        width: 100%;

        .schedule-calendar-view-row-group-heading {
            width: 100%;
            display: flex;
            border-top: 1px solid $border-color;
            background-color: $row-group-background-color;
            color: $row-group-color;
            padding-top: 1px;
            padding-left: 3px;
        }

        .schedule-calendar-view-row {
            width: 100%;
            display: flex;
            border-top: 1px solid $border-color;

            .schedule-calendar-view-row-label {
                width: 100%;
                max-width: $row-label-max-width;
                display: flex;
                background-color: $row-label-background-color;
                color: $row-label-color;
                padding-top: 1px;
                padding-left: 3px;
            }

            .schedule-calendar-view-cell {
                width: 100%;
                display: flex;
                flex-direction: column;
                border-left: 1px solid $border-color;
                padding-top: 2px;
                padding-bottom: 2px;
                padding-left: 2px;
                padding-right: 2px;
            }
        }
    }
}
