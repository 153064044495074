@import "../../styles/_colors.scss";
@import "../../styles/_variables.scss";

.tag-selector {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

    // We want to look like an input box.
    &.tag-selector-input {
        border: 1px solid $gray-300;
        border-radius: $input-border-radius;
        padding-left: $input-border-radius;
        padding-right: $input-border-radius;
    }

    // Each tag needs some spacing.
    .tag-selector-tag {
        border-radius: 0.3rem;
        margin-top: 0.1rem;
        margin-right: 0.2rem;
        margin-bottom: 0.1rem;
        font-size: 0.75rem;
    }

    // When we are readonly we don't want to show the pointer on hovering over our buttons.
    &.tag-selector-readonly {
        .btn {
            cursor: default;
        }
    }
}
