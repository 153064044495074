@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.stages-information-static {

    &-header {
        text-align: center;
        padding-bottom: 4rem;

        &-text {
            color: $primary;
            font-weight: 900;
            font-size: 2.5rem;
            margin-bottom: 1.5rem;
        }

        &-subtext {
            margin-bottom: 0.5rem;
            //color: $happy-cubs-dark-gray;

            &-link {
                text-decoration: none;
            }
        }
    }

    &-section {
        &-image {
        }

        &-header {
            &-text,
            &-subtext {
                color: $primary;
                font-size: 2rem;
                font-weight: 900;
            }

            &-subtext {
                font-size: 2rem;
            }
        }

        &-subheader {
            margin-top: 2rem;

            &-text {
                //color: $happy-cubs-dark-gray;
                font-size: 1.375rem;
                font-weight: 700;
                margin-bottom: 0.5rem;
            }
        }

        &-text {
            //font-weight: 900;
            font-size: 1rem;
        }

        &-swim-baby-cubs {
            background: url('../../../public/img/originals/Baby Cubs.jpg') no-repeat no-repeat;
            background-size: cover;
            background-position: center center;
        }

        &-swim-toddler-cubs {
            background: url('../../../public/img/originals/Toddler Cubs.jpg') no-repeat no-repeat;
            background-size: cover;
            background-position: center center;
        }

        &-swim-big-cubs {
            background: url('../../../public/img/originals/Big Cubs.jpg') no-repeat no-repeat;
            background-size: cover;
            background-position: center center;
        }

        &-music-baby-cubs {
            background: url('../../../public/img/originals/Baby Cub musics.jpg') no-repeat no-repeat;
            background-size: cover;
            background-position: center center;
        }

        &-music-toddler-cubs {
            background: url('../../../public/img/originals/Toddler Cubsmusic.jpg') no-repeat no-repeat;
            background-size: cover;
            background-position: center center;
        }

        &-music-big-cubs {
            background: url('../../../public/img/originals/Big Cubsmusic.jpg') no-repeat no-repeat;
            background-size: cover;
            background-position: center center;
        }

        &-music-family-cubs {
            background: url('../../../public/img/originals/Family Cubs music.jpg') no-repeat no-repeat;
            background-size: 80%;
            background-position: center 40%;
        }
    }
}
