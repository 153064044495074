@import "../../../styles/_breakpoints.scss";
@import "../../../styles/_colors.scss";

$background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url(./background/traffic-332857_1920.jpg);

.banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    //background-color: $dark-bg;
    //color: $dark-color;
    //background-image: $background-image;
    //color: $primary;

    .sticky-toolbar.sticky-toolbar-stuck {
        /*background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        background-image: $background-image;*/
        background-color: $white;
        color: $primary;
        border-bottom: 1px solid $primary;
    }
    // If we are using our full-height variant, then make sure we fill the available screen area.
    &.banner-full-height {
        height: auto;
        min-height: 100%;
    }

    // Colour the headings, but not if they show in a card.
    h1, h2, h3, h4, h5 {
        //font-size: 20px;
        color: $primary;
    }

    .card-header {
        h1, h2, h3, h4, h5 {
            //font-size: 20px;
            color: $body-color;
        }
    }
}

// Padding and sizes on different screen sizes.
.banner {
    //height: 140px;
/*    padding-left: 10px;
    padding-right: 10px;*/
    padding-top: 10px;
    padding-bottom: 15px;
}

@include media-breakpoint-up(sm) {
    .banner {
        //height: 150px;
/*        padding-left: 20px;
        padding-right: 20px;*/
        padding-top: 20px;
        padding-bottom: 20px;

        h1 {
            //font-size: 25px;
        }
    }
}

@include media-breakpoint-up(md) {
    .banner {
        //height: 200px;
/*        padding-left: 20px;
        padding-right: 20px;*/
        padding-top: 30px;
        padding-bottom: 20px;
    }
}
