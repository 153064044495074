@import "../../../styles/_breakpoints.scss";
@import "../../../styles/_colors.scss";
@import "../../../styles/_variables.scss";

.header {
    background-color: $navbar-bg;
    box-shadow: 0 0 7px rgba(0,0,0,0.1);
    // Make the menu and submenu look a bit nicer together.
    .header-menu-nav {
        .nav-item {
            .nav-link.active {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 1px;
            }
        }
    }

    .header-menu-sub-nav {
        box-shadow: 0 0 7px rgba(0,0,0,0.1);
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 25px;
        padding: 0 !important; /* Padding here to stop the nav overflowing */
    }
    /*    @include media-breakpoint-up(sm) {
        background: linear-gradient(to right, $white, $navbar-bg, $white,);
    }*/

    color: $navbar-color;
    //background-image: url('./header-decoration.svg');
    //height: 90px;
    border-bottom-style: none;
    border-bottom-style: none;
    //box-shadow: rgba($gray-300, 10) 0px 10px;

    height: $navbar-height;
    margin-bottom: $navbar-margin-bottom;
    // On mobiles, we want the logo to disapear.
    .app-navbar-brand {
        display: none;
    }

    @include media-breakpoint-down(sm) {
        .header-nav {
            font-size: 0.8rem;
        }

        .header-sidebar-toggle-before-brand svg,
        .header-sidebar-toggle-after-brand svg {
            color: transparent;
        }
    }

    @include media-breakpoint-up(sm) {
        .app-navbar-brand {
            display: inline-block;
        }

        header-nav {
        }
    }

    .header-menu-nav-container {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    // Show navigation via icons on mobile (xs and sm) and words above that size.
    .header-nav {
        .nav-link {
            padding: 0 6px;

            .nav-icon {
                display: inline-block;
            }

            span:not([data-route-group="member"]) {
                display: none;
            }

            @include media-breakpoint-up($navbar-include-menu-breakpoint-up) {
                .nav-icon {
                    display: none;
                }

                span:not([data-route-group="member"]) {
                    display: inline-block;
                }
            }
        }
    }
    /*    // Menu should show inline at sm and above.
    .header-menu-nav {
        display: none;
    }

    @include media-breakpoint-up($navbar-include-menu-breakpoint-up) {
        .header-menu-nav {
            display: flex;

            ul {
                flex-grow: 0;
            }
        }
    }*/
    // We have two sidebar toggles for positioning, but only one should show depending on screen size.
    .header-sidebar-toggle-before-brand {
    }

    .header-sidebar-toggle-after-brand {
        display: none;
    }

    @include media-breakpoint-up($navbar-include-menu-breakpoint-up) {
        .header-sidebar-toggle-before-brand {
            display: none;
        }

        .header-sidebar-toggle-after-brand {
            display: block;
        }
    }
}

// If it is not suggested that we show the sidebar, hide the after toggle (the one shown on non-mobile screens).
@include media-breakpoint-up($navbar-include-menu-breakpoint-up) {
    body:not([data-sidebar-suggested="true"]) {
        .header-sidebar-toggle-after-brand {
            display: none !important;
        }
    }
}
