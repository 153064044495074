@import "../../../styles/_breakpoints.scss";
@import "../../../styles/_colors.scss";
@import "../../../styles/_variables.scss";

$icon-margin: 6px;

.sidebar {
    position: fixed;
    top: 0px;
    height: calc(100vh - $navbar-height);
    display: flex;
    flex-direction: column;
    background-color: $sidebar-bg;
    color: $sidebar-color;
    border-top-right-radius: $sidebar-inner-corner-radius;
    border-bottom-right-radius: $sidebar-inner-corner-radius;
    margin-top: $navbar-height;
    // By default the sidebar is not visible and has no width, we have to toggle it on.
    padding-top: max(10px, $sidebar-inner-corner-radius);
    // Animate the width changes for sliding in etc.
    display: none;
    width: 0px;
    max-width: 0px;
    transition: width linear 0.5s;
    z-index: 999;

    .sidebar-header-navigation {
    }

    @include media-breakpoint-up($navbar-include-menu-breakpoint-up) {
        .sidebar-header-navigation {
            display: none;
        }
    }

    .sidebar-navigation-container {
        overflow-y: auto;
    }

    .sidebar-blank-space {
        flex-grow: 1;
    }
}

// Display of the sidebar when visible.
body[data-sidebar-visible="true"] {
    .sidebar {
        display: flex;
        width: $sidebar-width;
        max-width: $sidebar-width;
    }

    // Minimized display of the sidebar (only matters when visible).
    &[data-sidebar-minimized="true"] {
        .sidebar {
            // Width only big enough for the icons.
            width: $sidebar-minimized-width !important;
            max-width: $sidebar-minimized-width !important;
            // Hide words.
            .nav-item {
                white-space: nowrap;
                overflow: hidden;

                &.nav-title {
                    display: none;
                }

                .nav-link {
                    .nav-icon {
                        margin-right: $sidebar-minimized-width;
                    }
                }
            }

            &:hover {
                width: $sidebar-width !important;
                max-width: $sidebar-width !important;

                .nav-item {
                    .nav-link {
                        .nav-icon {
                            margin-right: $icon-margin;
                        }
                    }

                    &.nav-title {
                        display: block;
                    }
                }
            }
        }
    }
}


// On mobile, the sidebar floats, on bigger screens it doesn'#t.
@include media-breakpoint-up($navbar-include-menu-breakpoint-up) {
    body[data-sidebar-visible="true"] {
        .main {
            margin-left: $sidebar-width + $sidebar-margin-right;
        }

        .footer {
            margin-left: $sidebar-width + $sidebar-margin-right;
        }
        // Minimized display of the sidebar (only matters when visible).
        &[data-sidebar-minimized="true"] {
            .main {
                margin-left: $sidebar-minimized-width + $sidebar-margin-right !important;
            }

            .footer {
                margin-left: $sidebar-minimized-width + $sidebar-margin-right !important;
            }
        }
    }
}

