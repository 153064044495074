@import "../../../styles/_colors.scss";

// NOTE this colour is randomly picked because it looks nice.  It is not a brand colour.
//$event-background-color: rgb(6, 30, 66);
//$event-background-color: rgb(179, 186, 196);
//$event-background-color: $happy-cubs-medium-gray;
//$event-background-color: lighten( $happy-cubs-yellow, 30%);
$event-background-color: lighten( $happy-cubs-yellow, 40%);

.calendar-event {
    min-width: 100%;
    //max-width: 100%;
    background-color: $event-background-color;
    //background: linear-gradient(90deg, $event-background-color, lighten($event-background-color, 15%), $event-background-color);
    background: linear-gradient(90deg, $event-background-color, lighten($event-background-color, 5%), lighten($event-background-color, 15%));
    border-color: darken($event-background-color, 20%);
    //color: $white;
    color: $black;
    border-style: solid;
    border-width: 1px;
    margin-bottom: 2px;
    cursor: pointer;

    .calendar-event-event-time {
        //color: $gray-300;
        color: $black;
        font-size: 80%;
        padding-left: 3px;
    }

    .calendar-event-body {
        display: flex;
        width: 100%;

        .calendar-event-description-block {
            width: 100%;
            padding-right: 4px;
        }

        .calendar-event-staff {
            width: 100%;
            //border: 1px solid $primary;
            //border-radius: 15px;
            //padding-left: 15px;
            //padding-right: 15px;

            border-top: 1px solid $primary;
            margin-right: 6px;
            margin-bottom: 3px;
            padding-top: 2px;
            padding-bottom: 2px;
            font-size: 10px;

            .calendar-event-staff-PrimaryTeacher {
                font-weight: bold;
                font-size: 12px;
            }

            .calendar-event-staff-no-primary-teacher {
                font-weight: bold;
                font-size: 12px;
            }
        }

        .calendar-event-color-block {
            background-color: $primary; // Default if no color-index is supplied.
            display: block;
            width: 10px;
            max-width: 10px;
            border: 1px solid $black;
            margin-right: 3px;
            // Main color, plus complimentry colors given by ChatGPT with the prompt:
            // give me the hex code for thirty colours to complement this one #fada00.  Respond in the format: "&.calendar-event-color-block-color-[count] { background-color: [color]}" replacing [count] with the number of the complementary colour up to 30 and [color] with the hex code for it.
            &.calendar-event-color-block-color-0 {
                background-color: $primary;
            }

            &.calendar-event-color-block-color-1 {
                background-color: #ad00fa;
            }

            &.calendar-event-color-block-color-2 {
                background-color: #00adfa;
            }

            &.calendar-event-color-block-color-3 {
                background-color: #fa0095;
            }

            &.calendar-event-color-block-color-4 {
                background-color: #00fa9b;
            }

            &.calendar-event-color-block-color-5 {
                background-color: #9b00fa;
            }

            &.calendar-event-color-block-color-6 {
                background-color: #fa7600;
            }

            &.calendar-event-color-block-color-7 {
                background-color: #0076fa;
            }

            &.calendar-event-color-block-color-8 {
                background-color: #fa00ad;
            }

            &.calendar-event-color-block-color-9 {
                background-color: #00fa57;
            }

            &.calendar-event-color-block-color-10 {
                background-color: #5700fa;
            }

            &.calendar-event-color-block-color-11 {
                background-color: #fad100;
            }

            &.calendar-event-color-block-color-12 {
                background-color: #00d1fa;
            }

            &.calendar-event-color-block-color-13 {
                background-color: #fa0050;
            }

            &.calendar-event-color-block-color-14 {
                background-color: #00fa72;
            }

            &.calendar-event-color-block-color-15 {
                background-color: #7200fa;
            }

            &.calendar-event-color-block-color-16 {
                background-color: #fa8b00;
            }

            &.calendar-event-color-block-color-17 {
                background-color: #008bfa;
            }

            &.calendar-event-color-block-color-18 {
                background-color: #fa00e3;
            }

            &.calendar-event-color-block-color-19 {
                background-color: #00fa32;
            }

            &.calendar-event-color-block-color-20 {
                background-color: #3200fa;
            }

            &.calendar-event-color-block-color-21 {
                background-color: #faa600;
            }

            &.calendar-event-color-block-color-22 {
                background-color: #00a6fa;
            }

            &.calendar-event-color-block-color-23 {
                background-color: #fa003e;
            }

            &.calendar-event-color-block-color-24 {
                background-color: #00fa87;
            }

            &.calendar-event-color-block-color-25 {
                background-color: #8700fa;
            }

            &.calendar-event-color-block-color-26 {
                background-color: #faf200;
            }

            &.calendar-event-color-block-color-27 {
                background-color: #00f2fa;
            }

            &.calendar-event-color-block-color-28 {
                background-color: #fa0028;
            }

            &.calendar-event-color-block-color-29 {
                background-color: #00faa7;
            }

            &.calendar-event-color-block-color-30 {
                background-color: #a700fa;
            }
        }
    }
}
