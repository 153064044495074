@import "../../styles/_colors.scss";

.class-finder-location-day-card-body-class {
    align-items: center;
    margin-bottom: 0.5rem;

    &-time {
        font-size: 1rem;
        font-weight: 800;
    }

    &-stage {
        display: flex;
        align-items: center;
    }

    &-button {
        font-weight: 900;
    }
}
