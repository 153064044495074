@import "../../../../styles/_breakpoints.scss";
@import "../../../../styles/_colors.scss";

.waiting-lists-tab {
    margin: 0 1rem;

    .waiting-lists-tab-header {
        text-align: center;
        color: $primary;
    }

    .waiting-lists-tab-class-type {
        display: grid;
        grid-template-columns: 1fr;
        min-width: 100%;
        gap: 1rem;

        .waiting-lists-tab-class-type-header {
            text-align: center;
            margin: 1rem 0;
        }

        .waiting-lists-tab-class-type-class-classes {
            display: grid;
            gap: 1rem;
        }
    }
}

@include media-breakpoint-up(md) {
    .waiting-lists-tab {
        .waiting-lists-tab-class-type-class-classes {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@include media-breakpoint-up(lg) {
    .waiting-lists-tab {
        .waiting-lists-tab-class-type-class-classes {
            grid-template-columns: repeat(3, 1fr);
            justify-items: center;
        }
    }
}
