@import "../../../../styles/_colors.scss";

.payment-component {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
    padding-bottom: 8px;
    margin-bottom: 10px;

    .payment-component-description {
        font-size: 1.2rem;
    }

    .payment-component-amount {
        font-weight: bold;
    }

    .payment-component-paid-date {
        color: $success;
    }

    .payment-component-actions {
        margin-top: 10px;
    }
    // Color the border based on the payment state
    border-color: $warning;

    &.payment-component-Paid {
        border-color: $success;
    }

    &.payment-component-Failed {
        border-color: $danger;
    }
}
