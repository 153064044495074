@import "../../../../styles/_breakpoints.scss";
@import "../../../../styles/_colors.scss";

.class-component {
    color: $happy-cubs-body-text;
    font-weight: 600;

    .row {
        margin: 0; // Important as this keeps everything within the modal bounds.
    }

    &-header {
        background: $primary;

        &-location,
        &-class {
            color: $white;
            font-family: 'museosansrounded';
            font-size: 2rem;
            font-weight: 800;
        }

        &-class {
            font-size: 1rem;
            font-weight: 600;
        }
    }

    &-sub-header {
        &-icon,
        &-address {
            font-size: 1rem;
            font-family: 'museosansrounded';
            color: $primary;
            font-weight: 600;
        }
    }

    &-details {
        padding-top: 0;
        margin-top: -1rem;

        &-card {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;

            &-image {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                max-height: 180px;
                object-fit: cover;
                margin-left: -16px;
                margin-right: -16px;
                width: calc(100% + 32px);
            }

            &-body {
                &-dates {
                    &-icon {
                        color: $primary;
                        margin-right: 8px;
                    }
                }

                &-time {
                    &-icon {
                        color: $primary;
                        margin-right: 8px;
                    }

                    &-duration {
                        color: $primary;
                    }
                }

                &-stage {
                    &-icon {
                        width: 16px;
                        height: 16px;
                        margin-right: 8px;
                    }

                    &-ages {
                        color: $primary;
                    }
                }

                &-price {
                    &-icon {
                        color: $primary;
                        width: 16px;
                        height: 16px;
                        margin-right: 8px;
                    }

                    &-stages-info {
                        position: relative; // Required to set the z-index. Warning: The position: static property prevents the z-index from having an effect.
                        cursor: pointer;
                    }
                }

                &-button {
                    margin: 1rem 1rem 1rem 1rem;

                    &-top {
                        margin-top: 3rem;
                    }

                    &-bottom {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &-footer {
            border-top: none;

            &-type {
                color: $primary;
                font-size: 1.5rem;
                font-family: 'museosansrounded';
                font-weight: 600;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .class-details-modal {
        &-header {
            &-class {
                text-align: right;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .class-component {
        &-header {
            width: 100%;
            align-items: center;

            &-location {
                font-size: 3rem;
            }

            &-class {
                font-size: 2rem;
                text-align: right;
            }
        }

        &-details {
            width: 100%;

            &-card {
                overflow: hidden;
                padding: 0;

                &-image {
                    max-height: 400px;
                }

                &-body {
                    &-time,
                    &-stage,
                    &-price {
                        font-size: 1.75rem;
                    }

                    &-time {
                        &-duration {
                            font-size: 1.25rem;
                        }
                    }

                    &-stage {
                        &-ages {
                            font-size: 1.25rem;
                        }

                        & img {
                            width: 28px;
                            height: 28px;
                        }
                    }

                    &-price {
                        &-info {
                            font-size: 1.25rem;
                        }

                        &-icon {
                            width: 28px;
                            height: 28px;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .class-component {
        &-details {
            width: 100%;

            &-card {
                &-body {
                    &-time,
                    &-stage,
                    &-price {
                        font-size: 2rem;
                    }

                    &-time {
                        &-duration {
                            font-size: 1.5rem;
                        }
                    }

                    &-stage {
                        &-ages {
                            font-size: 1.5rem;
                        }

                        & img {
                            width: 32px;
                            height: 32px;
                        }
                    }

                    &-price {
                        &-info {
                            font-size: 1.5rem;
                        }

                        &-icon {
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
            }
        }
    }
}



