@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.class-finder-loading-message {
    text-align: center;
    font-weight: bold;
}

.class-finder-no-suitable-classes-message {
    margin-top: 20px;
    text-align: center;
    font-weight: bold;

    &-button {
        color: $happy-cubs-body-text;
        font-weight: bold;
    }
}

.class-finder {
    &-col {
        padding: 0;
    }

    &-header {
        position: relative;

        &-img {
            object-fit: cover;
        }

        &-title {
            position: absolute;
            bottom: 40px;
            right: 40px;
            color: $white;
            font-size: 2rem;
            font-weight: bold;
            font-family: 'museosansrounded';
        }
    }
}

@include media-breakpoint-up(md) {
    .class-finder {

        &-header {
            margin: 0 auto;

            &-img {
                width: 100%;
                max-height: 500px;
                object-fit: contain;
            }

            &-title {
                bottom: 60px;
                right: 100px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .class-finder {
        &-header {
            &-img {
                width: 100%;
                object-fit: contain;
            }

            &-title {
                bottom: 50px;
                right: 40px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .class-finder {
        &-header {
            &-img {
                width: 100%;
                object-fit: contain;
            }

            &-title {
                bottom: 70px;
                right: 420px;
            }
        }
    }
}
