@import "../../../styles/_colors.scss";

.styled-modal {
    width: 95%;
    margin: 0.5rem auto 0;

    .modal-content {
        border-top: 10px solid $primary;
    }
}

// Class that sets the maxHeight and positions the modal in the vertical center
.modal {
    max-height: 90vh !important;
    margin-top: 5vh;

    .modal-body {
        max-height: 70vh;
        overflow-y: auto;
    }
}
