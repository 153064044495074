@import "../../../../styles/_colors.scss";

.payment-card {
    border-radius: 0;
    border-color: $primary;
    margin-bottom: 1rem;

    &-title {
        color: $primary;
        font-weight: 600;
    }

    &-row {
        color: $happy-cubs-body-text;

        &-title {
            font-weight: 800;
        }
    }

    &-actions {
        margin-top: 2rem;

        &-center {
            margin: 0 auto;
        }
    }

    &-support {
        margin-top: 1rem;

        &-center {
            margin: 0 auto;
        }
    }
}
