﻿// Fonts
//



// Use the Poppins font.
/* devanagari */
/*@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}*/
/* latin-ext */
/*@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}*/
/* latin */
/*@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}*/

/* rimbo font */
@font-face {
    font-family: 'rimboregular';
    src: url('./fonts/rimbo/webfont/rimbo-regular-webfont.woff2') format('woff2'), url('./fonts/rimbo/webfont/rimbo-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* museosansrounded font */
@font-face {
    font-family: 'museosansrounded';
    font-style: normal;
    font-weight: 100;
    src: url('./fonts/museo/MuseoSansRounded100.woff') format('woff');
}

@font-face {
    font-family: 'museosansrounded';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/museo/MuseoSansRounded300.woff') format('woff');
}

@font-face {
    font-family: 'museosansrounded';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/museo/MuseoSansRounded500.woff') format('woff');
}

@font-face {
    font-family: 'museosansrounded';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/museo/MuseoSansRounded700.woff') format('woff');
}

@font-face {
    font-family: 'museosansrounded';
    font-style: normal;
    font-weight: 900;
    src: url('./fonts/museo/MuseoSansRounded-900-webfont.woff2') format('woff2'), url('./fonts/museo/MuseoSansRounded-900-webfont.woff') format('woff');
}

/* rimbo is only used for h1. */
h1 {
    font-family: 'museosansrounded';
    font-weight: 700;
}

h2, h3, h4, h5, h6 {
    font-family: 'museosansrounded';
    font-weight: 500;
}

a.btn {
    font-family: 'museosansrounded';
    font-weight: 300;
}

/* Calibr is the main font. */
body {
    font-family: Arial, 'Trebuchet', serif;
}

/* devanagari */
/*@font-face {
  font-family: 'Poppins800';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDD4Z11lFc-K.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}*/
/* latin-ext */
/*@font-face {
  font-family: 'Poppins800';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDD4Z1JlFc-K.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}*/
/* latin */
/*@font-face {
  font-family: 'Poppins800';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDD4Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}*/

/*$font-family-sans-serif: Poppins, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;*/

//$font-base-size: 1em;