@import "../../../styles/_breakpoints.scss";
@import "../../../styles/_colors.scss";

//$background-image-default: linear-gradient(rgba(255, 255, 255, 255.9), rgba(255, 255, 255, 0.7)), url(./default/hub-4413618_1920.jpg);
//$background-image-default: linear-gradient(rgba(255, 255, 255, 255.9), rgba(255, 255, 255, 0.7)), url(../header/appNavbarBrand/logo.svg);
//$background-image-default: url(./background.jpg);

// Put the default background on the body (to avoid flicker switching around and allow it to be changed by supplying different data-background="" values.
body {
    //background-image: $background-image-default;
    background-color: $white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    //color: $dark-color;
    min-height: 100vh;
    background-attachment: fixed;
}

body[data-background="default"] {
    //background-image: $background-image-default;
    background-size: cover;
}

body[data-background="account"] {
    //background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.3)), url('account/water-5245722_1920.jpg');
    //background-size: cover;

    background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.3)), url('account/hc-toddler.jpeg') no-repeat; // Files shared with HC - Desktop-Image / Mobile-image
    background-size: cover;
    //background-color: $primary; // Files shared with HC - Desktop-Yellow / Mobile-Yellow
}

/*body[data-background="links"] {
    background-image: $background-image-links;
    background-size: cover;
}

body[data-background="documents"] {
    background-image: $background-image-documents;
    background-size: cover;
}
*/