@import "../../../../styles/_variables.scss";

.app-navbar-brand {
    min-width: 200px;
    min-height: $navbar-height - 10px;
    height: 100%;
    background-image: url('../../../../styles/images/Happy_Cubs_Horizontal_plus_line.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-clip: padding-box;
    cursor: pointer;
}
