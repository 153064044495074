@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.add-child-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: solid 2px $secondary;
    border-radius: 1rem;
    min-width: 100%;
    color: $secondary;
    cursor: pointer;

    .add-child-component-icon {
        font-size: 2rem;
        padding-right: 8px;
        margin: 0;
    }

    .add-child-component-text {
        font-size: 1.25rem;
        margin: 0;
    }
}
