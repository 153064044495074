@import "../../../../styles/_colors.scss";

.dates-table {
    &-lessonDate, &-holidayDate {
        cursor: pointer;
        transition: ease-in .2s all;

        &:hover {
            color: $primary;
        }
    }

    &-holidayDate:hover {
        color: $danger;
    }
}
