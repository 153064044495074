@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.class-finder-location-day {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 1rem;

    .row {
        //margin: 0;
    }

    &-card {
        border-radius: 0;
        border-color: $primary;
        padding-left: 4px;
        padding-right: 4px;

        &-header {
            background-color: transparent;
            padding-left: 4px;
            padding-right: 4px;
            //border-bottom: none;
            border-color: $primary;

            &-row {
                align-items: center;

                &-name {
                    font-size: 1.5rem;
                    font-weight: 700;
                    font-family: 'museosansrounded';
                    color: $happy-cubs-body-text;
                    margin: 0;
                    margin-left: 8px;
                }

                &-icon {
                    color: $primary;
                    width: 30px;
                    height: 30px;
                }
            }
        }

        &-body {
            padding-left: 4px;
            padding-right: 4px;
        }
    }
}

@include media-breakpoint-up(md) {
    .class-finder-location-day {
        max-width: 640px;
        margin: 0 auto !important;
    }
}