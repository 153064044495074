@import "../../../../styles/_breakpoints.scss";
@import "../../../../styles/_colors.scss";

.classes-tab {
    //margin: 0 1rem;

    .classes-tab-header {
        text-align: center;
        color: $primary;
    }

    .classes-tab-class-type {
        display: grid;
        grid-template-columns: 1fr;
        min-width: 100%;
        gap: 1rem;

        .classes-tab-class-type-header {
            text-align: center;
            margin: 1rem 0;
            font-family: 'museosansrounded';
            font-weight: 600;
        }

        .classes-tab-class-type-class-classes {
            display: grid;
            gap: 1rem;
        }
    }
}

@include media-breakpoint-up(md) {
    .classes-tab {
        width: 640px;
        margin: 0 auto;
    }
}

@include media-breakpoint-up(lg) {
    .classes-tab {
        width: 800px;
        margin: 0 auto;

        .classes-tab-class-type-header {
            font-size: 3rem;
        }

        .classes-tab-class-type-class-classes {
            justify-items: center;
        }
    }
}
