@import "../../styles/_colors.scss";

.notice-board {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &-item {
        border-left: solid 5px $primary;
        padding-left: 1rem;
        transition: border-color ease-in 300ms;

        &-content {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            &-title {
                font-weight: bold;
            }

            &-date {
                color: $secondary;
            }
        }

        &:hover {
            border-color: rgba($primary, 0.5);
        }
    }
}
