.timer {
    position: relative;
    width: 100px;
    height: 100px;

    &-svg {
        transform: scaleX(-1);
    }

    &-circle {
        fill: none;
        stroke: none;

        &-path-elapsed {
            stroke-width: 7px;
            stroke: grey;
        }

        &-path-remaining {
            stroke-width: 7px;
            stroke-linecap: round;
            transform: rotate(90deg);
            transform-origin: center;
            transition: 1s linear all;
            fill-rule: nonzero;
            stroke: currentColor;

            &-green {
                color: rgb(65, 184, 131);
            }

            &-orange {
                color: orange;
            }

            &-red {
                color: red;
            }
        }
    }

    &-label {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 10px;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        color: #fada00;
    }
}
