@import "../../../styles/_colors.scss";
@import "../../../styles/_breakpoints.scss";
@import "../../../styles/_variables.scss";

.footer {
    //background-color: $white;
    //color: $body-color;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    margin-top: 1rem;
    border-top-style: none;

    .footer-nav {
        justify-content: center;

        .nav-link {
            color: $dark-color;
        }

        .nav-icon {
            display: none;
        }

        flex-direction: row;
    }

    .footer-logo {
        display: block;
        min-height: 24px;
        min-width: 24px;
        max-height: 24px;
        max-width: 24px;
        background-image: url('../../../styles/images/Cub.png');
        background-size: cover;
    }
}

// Special styling to round the top left corner if the sidebar has a rounded bottom right corner and the sidebar is being rendered fixed
// rather than over the top of content.
@include media-breakpoint-up($navbar-include-menu-breakpoint-up) {
    body[data-sidebar-visible="true"] {
        .footer {
            border-top-left-radius: $sidebar-inner-corner-radius;
        }
    }
}

