@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.members-children-item-child {
    display: flex;
    flex-direction: column;
    border: solid 2px $secondary;
    border-radius: 1rem;
    min-width: 100%;
    // Header section
    &-header {
        display: flex;
        flex-direction: column;
        text-align: center;

        &-topbar {
            background: $primary;
            width: 100%;
            padding: 10px 0 5px 0;
            border-top-right-radius: 1rem;
            border-top-left-radius: 1rem;

            &-child-number,
            &-child-name,
            &-child-age {
                font-weight: 900;
                margin: 0;
            }

            &-child-number {
                color: $white;
            }

            &-child-name {
                color: $white;
                font-size: 2.2rem;
            }

            &-child-age {
                font-weight: 900;
            }
        }
    }
    // No Classes Section
    &-no-classes {
        text-align: center;
        padding: 20px 0 0;

        &-header {
            color: $primary;
            margin: 0;
        }

        &-icon {
            font-size: 2rem;
            margin: 6px 0 6px;
        }

        &-footer {
            color: $happy-cubs-dark-gray;
            font-weight: 700;
            margin: 0;
        }
    }
    // First Class Section
    &-first-class {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0 0;
        text-align: center;

        & p {
            padding: 0;
            margin: 0;
        }

        &-header {
            color: $primary;
            font-weight: 700;
            font-family: 'museosansrounded';

            &-term {
                color: $secondary;
                font-style: italic;
                font-weight: normal;
            }
        }

        &-details,
        &-stage,
        &-next {
            color: $secondary;
            font-weight: 700;
        }

        &-next {
            color: $primary;
            font-weight: normal;
        }
    }
    // Find Class Buttons Section
    &-find-class-buttons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 10% 0;

        &-swim,
        &-music {
            //border-radius: 5px;
            //font-size: 1rem;
            font-family: "museosansrounded";
            font-weight: 900;
        }

        &-swim {
            background: $happy-cubs-mint;
            border-color: $happy-cubs-mint;

            &:hover {
                background: darken($happy-cubs-mint, 10%) !important;
                border-color: darken($happy-cubs-mint, 10%) !important;
            }
        }

        &-music {
            background: $happy-cubs-medium-gray;
            border-color: $happy-cubs-medium-gray;

            &:hover {
                background: darken($happy-cubs-medium-gray, 10%) !important;
                border-color: darken($happy-cubs-medium-gray, 10%) !important;
            }
        }
    }

    &-manage {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 10% 0;
        margin-bottom: 1rem;
    }
}

@include media-breakpoint-up(md) {
    .members-children-item-child {
        width: 45%;

        &-header {
            &-topbar {
                &-child-name {
                    font-size: 2.3rem;
                }
            }
        }

        &-first-class {
            &-next {
                font-size: 1.15rem;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .members-children-item-child {
        width: 30%;

        &-header {
            &-topbar {
                &-child-name {
                    font-size: 2.5rem;
                }
            }
        }
    }
}
