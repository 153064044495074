@import "../../styles/_colors.scss";

.profile-image-component {
    position: relative;
    top: 6px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: solid 4px $white;
    margin-bottom: 1rem;
}

.profile-image-component-small {
    width: 70px !important;
    height: 70px !important;
}

.profile-image-component-tiny {
    width: 40px !important;
    height: 40px !important;
}
