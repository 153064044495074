@import "../../styles/_colors.scss";

.class-navigator {
    &-classes {
        &-headings {
            color: $secondary;

        }

        &-card {
            border-left: solid 4px $primary;

            &-headings {
                color: $primary;
            }
        }

        &-link {
            color: #737373;
            font-weight: bold;
            text-decoration: none;
            transition: ease-in 300ms;

            &:hover {
                color: $primary;
            }
        }
    }

    &-registers {
        border-left: solid 4px $happy-cubs-dark-gray;

        &-headings {
            color: $happy-cubs-dark-gray;
        }

        &-link {
            color: #737373;
            font-weight: bold;
            text-decoration: none;
            transition: ease-in 300ms;

            &:hover {
                color: $primary;
            }
        }
    }
}
