﻿// Configuration of variables that are passed to bootstrap and any theme.
//

@import "./_colors.scss";
@import "./_fonts.scss";

// Variables used by our own layout.
//

// Navbar
$navbar-bg: $white;
//$navbar-color: $light-color;
$navbar-color: $happy-cubs-dark-gray;
$navbar-active-color: $happy-cubs-yellow;
$navbar-height: 100px;
$navbar-margin-bottom: 10px;
$navbar-include-menu-breakpoint-up: lg;

// Sidebar
$sidebar-bg: $dark-bg;
$sidebar-color: $dark-color;
$sidebar-icon-color: $gray-400;
$sidebar-width: 220px;
$sidebar-minimized-width: 60px;
$sidebar-inner-corner-radius: 50px;
$sidebar-highlight-bg: $primary;
$sidebar-highlight-color: $white;
$sidebar-highlight-icon-color: $white;
$sidebar-margin-right: 10px;


// Customise bootstrap variables.
//

// We want to change the colour contrast ratio to make sure we end up with white on the yellow brand buttons as per the 
// This is less accessible than bootstrap recommend, so it is recommneded that we delete this line if the brand yellow color ever changes and
// we can go back to the default accessiblity rules in bootstrap 4.5.
// For reference (comment from bootstrap/_variables.scss):
// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 1.3;

// We want cards to be more rounded than normal.
$card-border-radius: 2rem;
$card-spacer-x: 1.5rem;
$card-spacer-y: 1rem;

// We want buttons to be very round.
$btn-border-radius: 1rem;
$btn-padding-x: 1rem;
$btn-border-radius-sm: 1rem;
$btn-padding-x-sm: 1rem;

// We want rounded inputs.
$input-border-radius: 1rem;


// Pull in boostrap default variables so we can use them elsewhere in our own components if needed.
//
@import "../../node_modules/bootstrap/scss/mixins";
