@import "../../../styles//_breakpoints.scss";
@import "../../../styles/_colors.scss";

$contentWidth: 80%;
$museoFont: 'museosansrounded';

.class-search {
    text-align: center;
    //padding: 0 5% 0;

    &-card {
        display: flex;
        flex-direction: column;

        &-title {
            font-weight: 900;
            color: $primary;
        }

        &-subtitle {
            font-weight: 500;
            color: $secondary;
            width: $contentWidth;
            margin: 0 auto;
            margin-bottom: .75rem;
        }

        &-form-group {
            width: $contentWidth;
            margin: 0 auto;

            &-label {
                color: $secondary;
                font-weight: 700;
                font-family: $museoFont;
            }

            &-select {
                background-color: $primary;
                border: $primary;
                color: $white;
                font-size: 1.25rem;
                font-weight: 900;
                font-family: $museoFont;

                &-option {
                    background: white;
                    color: $secondary;
                }

                &-dob {
                    background-image: none;
                }
            }
        }

        &-footer {
            margin: 0.5rem 0;

            &-button {
                width: $contentWidth;
                font-weight: 900;
                font-family: $museoFont;
                font-size: 1.5rem;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .class-search {
        margin-top: 2rem;
    }
}

@include media-breakpoint-up(md) {
    .class-search {
        margin: 0 auto;
        max-width: 600px;
    }
}
